import * as React from 'react'
import Link from 'gatsby-link'
import imgBpLogo from '../../static/images/logos/bridgephase.svg'
import imgDancingWill from '../../static/images/bp-photos/dancing-will.gif'

const NotFoundPage = () => (
  <div>
    <main className="container text-center">
      <Link to="/">
        <img src={imgBpLogo} style={{ width: '12rem', margin: '5rem auto' }} />
      </Link>
      <p>Bummer, that page couldn't be found.</p>
      <p>How about a GIF of Will dancing instead?</p>
      <img src={imgDancingWill} style={{ width: '12rem', margin: 'auto' }} />
    </main>
  </div>
)

export default NotFoundPage
